.css-aym9vq-MuiButtonBase-root-MuiTab-root
{
   background-color: rgb(51, 51, 51) !important;
}
.tabText
{
    font-family: 'Nunito' !important;
    font-weight: bold !important;
    font-size: 12px !important;
    min-height: 20px !important;
    //letter-spacing: 1.25px;
}
.toggleText
{
    font-family: 'Nunito' !important;
    font-weight: bold !important;
    font-size: 12px !important;
   
}
.resetButton
{
    font-weight: bold !important;
    font-size: 12px !important;
    color: #333333;
}
.applyText
{
    font-weight: bold !important;
    font-size: 8px !important;
    color: #FFFFFF;
}

.MuiPopover-paper
{
border-bottom-left-radius:20px !important ;
border-bottom-right-radius: 20px !important;    
}