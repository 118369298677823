.sub_items {
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
flex-direction: row;
height: auto;
}
.upcomingStyle
{
    font-family: 'Nunito' !important;
    font-weight: bold !important;
    font-size: 23px !important;
    color: #333333;
    padding-left: 3%;
}
.upcomingDate
{
    font-family: 'Nunito' !important;
    font-weight: bold !important;
    font-size: 20px !important;
    color: #333333;
    align-items: flex-end;
   opacity: 0.5;
   //text-indent: 800px !important;
}