
/* @import url('https://fonts.googleapis.com/css?family=Nunito+Sans:400,400i,700,800,800i,900i&display=swap');
@import url('https://fonts.googleapis.com/css?family=Nunito:400,400i,700,800,800i,900i&display=swap'); */

@font-face {
  font-family: 'Tiempos-Black';
  font-weight: 900;
  src: local('Tiempos'), url(./assets/fonts/TiemposHeadline-Black.otf) format('opentype');
}
@font-face {
  font-family: 'Tiempos-Bold';
  font-weight: 900;
  src: local('Tiempos'), url(./assets/fonts/TiemposHeadline-Bold.otf) format('opentype');
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue','Nunito Sans','Nunito'
    sans-serif,'Tiempos';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.MuiInputBase-input-MuiFilledInput-input
{
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}
.MuiInputBase-root-MuiFilledInput-root
{
    font-family: 'Nunito'!important;
    font-weight: 600 !important;
    font-size: 17px !important;
    color: #2B2B2B !important;
    border-radius: 15px !important;
    background-color: #F6F6F6 !important; 
    line-height: 1.2em !important;
    padding-right: 130px !important;
    width: '100%'!important;
    align-items: center !important;
    justify-content: center !important;
}
.MuiList-root-MuiMenu-list
{
  background-color: #333333 !important;
}

.commonButtonSize{
  font-family: 'Nunito' !important;
  font-weight: bold !important;
  font-size: 12px !important;
  letter-spacing: 1.25px;
  height: 30px !important;
}
.react-datepicker {
  position: absolute !important;
  right: unset !important;
  width: max-content;
}