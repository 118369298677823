
.loginBackground {
    background: url(../../assets/images/logi.jpg);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 100vh;
}

.login {
    background: white;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 100vh;
    padding-top: 5em;
}

.loginheading
{
    font-size: 22;
    color: #333333;
    display: flex ;
    font-weight:700;
    font-family: 'Nunito' !important;
    font-weight: bold;
    padding-bottom: 0px !important;
}
.forgotText
{
    font-size: 14px !important;
    color: '#333333';
    font-weight: 700;
    font-family: 'Nunito';
}
.loginButton
{
    font-family: 'Nunito';
    // font-weight: bold !important;
    font-size: 12px !important;
    color: #FFFFFF;
    text-transform: none !important;
}
.accountText
{
    //font-weight: 600 !important;
    font-size: 13px !important;
    font-family: 'Nunito';
}
.googleButton
{
    font-family: 'Nunito' !important;
    font-weight: bold;
    font-size: 12px !important;
    color: #FFFFFF;
    text-transform: none !important;
}