.totalText
{
    font-family: 'Nunito'!important;
    font-weight: bold !important;
    font-size: 16px !important;
    color: #2B2B2B;
}
.arrivalText
{
    
    font-size: 20px !important;
    font-weight: bold !important;
    padding-bottom: 12%;
    padding-top: 3%;
    padding-left: 1%;
}
